<template>
  <div class="mentoring">
    <section class="intro">
      <img
        src="@/assets/images/banner-mentoring@1x.jpg"
        srcset="
          @/assets/images/banner-mentoring@1x.jpg 1x,
          @/assets/images/banner-mentoring@2x.jpg 2x,
          @/assets/images/banner-mentoring@3x.jpg 3x
        "
        alt="Photo of instructor with student using Tower"
        class="banner"
      />
      <div class="intro-content">
        <h1 class="h1 narrow">Community Mentoring</h1>
        <h1 class="h2 wide" style="white-space:nowrap">Community Mentoring</h1>
        <hr>
        <p class="p2">Balanced Body's Instructor Mentoring Program is an opportunity for Black Instructors and Instructors-in-Training to receive online mentoring, free-of-charge, in a Zoom meeting format.</p>
      </div>
    </section>

    <section class="primary">
      <div class="primary-inner">
        <div class="flex">
          <h3 class="h3">Casual, Friendly Environment to Ask Questions</h3>
          <p class="p3">Our Mentors are Balanced Body Master Instructors and certified Pilates Instructors with extensive experience establishing themselves as a Pilates Instructor, building their business (personal and studio) and working with clients. Each of our Mentors have committed to 2 online group mentoring sessions per month on the same general topic. The 2 sessions are not progressive and attending both sessions in one month is not required.  All group mentoring sessions are provided by Balanced Body at no-charge.</p>
          <hr>
        </div>
        <div class="wide-flex">
          <img
            src="@/assets/images/img-mentoring@1x.jpg"
            srcset="
              @/assets/images/img-mentoring@1x.jpg 1x,
              @/assets/images/img-mentoring@2x.jpg 2x,
              @/assets/images/img-mentoring@3x.jpg 3x
            "
            alt="Woman doing Cadillac workout"
          />
          <div class="flex">
            <h3 class="h5">The Details</h3>
            <p class="p4">We encourage you to register for the Mentoring Program sessions you are interested in attending as we need at least 3 people for each Session to go.</p>
            <p class="p4">With the  Mentoring Program we are creating a safe, welcoming place where you can go to get answers, discuss the experience of being a Black instructor in the Pilates community and meet like-minded peers to support your growth.</p>
          </div>
        </div>
      </div>
      <span class="triangle" />
    </section>

    <section class="cards">
      <span class="cards-triangle" />
      <div class="cards-inner">
        <info-card
          v-for="(bio, index) in bios"
          :key="index"
          :bio=bio
        />
      </div>
    </section>

    <section class="quote">
      <div class="quote-inner">
        <img src="@/assets/images/quotes.svg" alt="Quotation marks" />
        <div class="flex">
          <p class="callout">I have come to believe over and over again that what is most important to me must be spoken, made verbal and shared.</p>
          <hr>
          <h5 class="h5">Audre Lorde</h5>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import InfoCard from "@/components/InfoCard.vue";

  export default {
    name: "Mentoring",

    metaInfo: {
      title: "Community Mentoring",
      titleTemplate: "%s | Diversity in Pilates",
      meta: [
        {
          name: "description",
          content: "Balanced Body's Instructor Mentoring Program is an opportunity for Black Instructors and Instructors-in-Training to receive online mentoring, free-of-charge, in a Zoom meeting format."
        }
      ]
    },

    components: {
      InfoCard
    },

    data() {
      return {
        bios: [
          // {
          //   image: "img-tiza-wynn-riley",
          //   name: "Tiza Wynn Riley",
          //   description: "Owner of Nurture Works<br>BB Master instructor<br>Los Angeles, California",
          //   ig: "nurtureworks",
          //   fb: "nurtureworkshealing",
          //   url: "https://nurtureworkshealing.com/",
          //   courses: "Pilates, CoreAlign",
          //   modal: "modal6",
          //   title: "Classes with Clients with Special Considerations",
          //   // date1: "Thursday, Feb 3, 2022",
          //   date2: "Thursday, Feb 3, 2022",
          //   banner: "Feb 2022",
          //   // zoom1: "https://zoom.us/meeting/register/tJIpcOysqD4rHt07p1IRg2hCKhxy61agLAbE",
          //   zoom2: "https://pilates.zoom.us/meeting/register/tJwqdu6grjIqE9bBXX33djWpRgtT0hSFpzix",
          //   color: "event6"
          // },
          // {
          //   image: "img-labriece-ochsner",
          //   name: "LaBriece Ochsner",
          //   description: "Owner of LB Pilates Studios<br>Life Coach and Mentor<br>Biel, Switzerland and Online",
          //   ig: "labriecepilates",
          //   fb: "dani.labutter",
          //   url: "https://lb-pilates.ch",
          //   courses: "Pilates, Bodhi Suspension System",
          //   modal: "modal1",
          //   title: "Pilates for the Athletic Client & the Professional Athlete",
          //   // date1: "Thursday, Mar 3, 2022",
          //   date2: "Thursday, Mar 3, 2022",
          //   banner: "Mar 2022",
          //   // zoom1: "https://zoom.us/meeting/register/tJMud-yrrDwpG9djGPGEh8hmlQLTfOZfLcIF",
          //   zoom2: "https://pilates.zoom.us/meeting/register/tJcld--vpjoiEt3AOrDH9Tot6XEAxp_FGgRS",
          //   color: "event1"
          // },
          // {
          //   image: "img-sylvia-byrd-leitner",
          //   name: "Sylvia Byrd-Leitner, MFA",
          //   description: "Certified Pilates Instructor<br>Registered Yoga Teacher<br>Cherry Hill, New Jersey",
          //   ig: "pilatescorecenter",
          //   fb: "PilatesCoreCenter",
          //   url: "http://pilatescorecenter.com",
          //   courses: "Pilates, CoreAlign, MOTR, Barre, AI3D",
          //   modal: "modal4",
          //   title: "From ADL (Activities of Daily Living) to Reformer",
          //   // date1: "Thursday, Apr 7, 2022",
          //   date2: "Thursday, Apr 7, 2022",
          //   banner: "Apr 2022",
          //   // zoom1: "https://zoom.us/meeting/register/tJcrf-qqrDotHdJ81bzeKeJrVKKOiXtyjL69",
          //   zoom2: "https://pilates.zoom.us/meeting/register/tJEtdOuoqDwuHtK90bUF9_XYtEL2Xpy00cHn",
          //   color: "event4"
          // },
          // {
          //   image: "img-misty-lynne-cauthen",
          //   name: "Misty Lynne Cauthen",
          //   description: "Owner of Dragonfly Pilates<br>Trained Dancer<br>Pittsburgh, Pennsylvania",
          //   ig: "dragonflypilates",
          //   fb: "DragonflyPilates",
          //   url: "https://dragonflypilates.com/",
          //   courses: "Pilates, Barre, Bodhi Suspension System",
          //   modal: "modal2",
          //   title: "Mixed Level",
          //   // date1: "Thursday, Sep 1, 2022",
          //   date2: "Thursday, Sep 1, 2022",
          //   banner: "Sep 2022",
          //   // zoom1: "https://zoom.us/meeting/register/tJ0qceCuqDMvH9xbghYl7GYbD3abxWuY_zAU",
          //   // zoom2: "https://zoom.us/meeting/register/tJEsc-GpqDIqGdGNZ5ISvB5uyMXhRjh01UeS",
          //   color: "event2",
          //   nolink: "true"
          // },
          // {
          //   image: "img-fabrice-lamego",
          //   name: "Fabrice Lamego",
          //   description: "Dancer &amp; Choreographer<br>Owner: Pilates Pour Tous Studio<br>Lyon, France</p>",
          //   ig: "pilates_pour_tous_studio",
          //   fb: "Pilates-Pour-Tous-Studio-128236503940565",
          //   url: "https://pilatespourtousstudio.com",
          //   courses: "Pilates, MOTR, AI3D",
          //   modal: "modal3",
          //   title: "Creative Mat Work",
          //   date1: "Thursday, Jan 7, 2021",
          //   date2: "Thursday, Jan 21, 2021",
          //   banner: "Jan 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJYud-ytrzsjG9YWhOtUbvEfQErsuW9Rjwab",
          //   zoom2: "https://zoom.us/meeting/register/tJIkd-usrDwjHNOzok5rqv1J03qiM99JzsqL",
          //   color: "event3"
          // },
          // {
          //   image: "img-sylvia-byrd-leitner",
          //   name: "Sylvia Byrd-Leitner, MFA",
          //   description: "Certified Pilates Instructor<br>Registered Yoga Teacher<br>Cherry Hill, New Jersey",
          //   ig: "pilatescorecenter",
          //   fb: "PilatesCoreCenter",
          //   url: "http://pilatescorecenter.com",
          //   courses: "Pilates, CoreAlign, MOTR, Barre, AI3D",
          //   modal: "modal4",
          //   title: "Teaching from the Heart",
          //   date1: "Thursday, Feb 4, 2021",
          //   date2: "Thursday, Feb 18, 2021<br/><span style='font-size:10px;line-height:1;display:inline-block'>Note: this session is not related to Jenna Zaffino’s Teach from the Heart program.</span>",
          //   banner: "Feb 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJcrf-qqrDotHdJ81bzeKeJrVKKOiXtyjL69",
          //   zoom2: "https://zoom.us/meeting/register/tJYvd-CqpzMiE9yOb_YwddY_EMHWvkVJUE1l",
          //   color: "event4"
          // },
          // {
          //   image: "img-tonya-amos",
          //   name: "Tonya Amos",
          //   description: "Owner of Aspire Pilates<br>Dancer &amp; Activist<br>Concord, California",
          //   ig: "GrownWomenDanceCollective",
          //   fb: "GrownWomenDanceCollective",
          //   url: "https://grownwomendance.org/",
          //   courses: "Pilates, AI3D",
          //   modal: "modal5",
          //   title: "Elder Power: Keeping our Seniors Strong",
          //   date1: "Thursday, Mar 4, 2021",
          //   date2: "Thursday, Mar 18, 2021",
          //   banner: "Mar 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJcscuGrrjwoEtCY-nk1e9HAsPUF-JIr6QsN",
          //   zoom2: "https://zoom.us/meeting/register/tJEkcu-hrzIjGNHaKRMEphudGSdoX1VlVcoH",
          //   color: "event5"
          // },
          // {
          //   image: "img-tiza-wynn-riley",
          //   name: "Tiza Wynn Riley",
          //   description: "Owner of Nurture Works<br>BB Master instructor<br>Los Angeles, California",
          //   ig: "nurtureworks",
          //   fb: "nurtureworkshealing",
          //   url: "https://nurtureworkshealing.com/",
          //   courses: "Pilates, CoreAlign",
          //   modal: "modal6",
          //   title: "It's all about the Details",
          //   date1: "Thursday, Apr 1, 2021",
          //   date2: "Thursday, Apr 15, 2021",
          //   banner: "Apr 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJIpcOysqD4rHt07p1IRg2hCKhxy61agLAbE",
          //   zoom2: "https://zoom.us/meeting/register/tJAvdO2vrDkrEtbFKAn72seozkseJ1pHzDEy",
          //   color: "event6"
          // },
          // {
          //   image: "img-fabrice-lamego",
          //   name: "Fabrice Lamego",
          //   description: "Dancer &amp; Choreographer<br>Owner: Pilates Pour Tous Studio<br>Lyon, France</p>",
          //   ig: "pilates_pour_tous_studio",
          //   fb: "Pilates-Pour-Tous-Studio-128236503940565",
          //   url: "https://pilatespourtousstudio.com",
          //   courses: "Pilates, MOTR, AI3D",
          //   modal: "modal3",
          //   title: "Dynamic Class Design",
          //   date1: "Thursday, May 6, 2021",
          //   date2: "Thursday, May 20, 2021",
          //   banner: "May 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJwqcu6srDopHdbewZbScv8ihUYXbitC2NqC",
          //   zoom2: "https://zoom.us/meeting/register/tJAqdu-spjgoGtD7z0RbOuo12ik_6VQb-aaX",
          //   color: "event1"
          // },
          // {
          //   image: "img-labriece-ochsner",
          //   name: "LaBriece Ochsner",
          //   description: "Owner of LB Pilates Studios<br>Life Coach and Mentor<br>Biel, Switzerland and Online",
          //   ig: "labriecepilates",
          //   fb: "dani.labutter",
          //   url: "https://lb-pilates.ch",
          //   courses: "Pilates, Bodhi Suspension System",
          //   modal: "modal1",
          //   title: "Optimizing Equipment",
          //   date1: "Thursday, Jun 3, 2020",
          //   date2: "Thursday, Jun 17, 2020",
          //   banner: "Jun 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJAtceyqrD4iHNLjqv2W7ai1MIpyLIwPBpfU",
          //   zoom2: "https://zoom.us/meeting/register/tJEpdOmrrT4sG9N34nzdj6iWDHeWOoIclG9J",
          //   color: "event2"
          // },
          // {
          //   image: "img-tiza-wynn-riley",
          //   name: "Tiza Wynn Riley",
          //   description: "Owner of Nurture Works<br>BB Master instructor<br>Los Angeles, California",
          //   ig: "nurtureworks",
          //   fb: "nurtureworkshealing",
          //   url: "https://nurtureworkshealing.com/",
          //   courses: "Pilates, CoreAlign",
          //   modal: "modal6",
          //   title: "Customizing Exercises",
          //   date1: "Thursday, Jul 1, 2021",
          //   date2: "Thursday, Jul 15, 2021",
          //   banner: "Jul 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJ0qcOGhrjguGdCh77FoqB27bqONUpI6FfWW",
          //   zoom2: "https://zoom.us/meeting/register/tJYpcuCqrDgiGNInEvyrEVfpLcFukQrM8NSN",
          //   color: "event3"
          // },
          // {
          //   image: "img-sylvia-byrd-leitner",
          //   name: "Sylvia Byrd-Leitner, MFA",
          //   description: "Certified Pilates Instructor<br>Registered Yoga Teacher<br>Cherry Hill, New Jersey",
          //   ig: "pilatescorecenter",
          //   fb: "PilatesCoreCenter",
          //   url: "http://pilatescorecenter.com",
          //   courses: "Pilates, CoreAlign, MOTR, Barre, AI3D",
          //   modal: "modal4",
          //   title: "Reformer Refinement",
          //   date1: "Thursday, Aug 5, 2021",
          //   date2: "Thursday, Aug 19, 2021",
          //   banner: "Aug 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJ0tc--gpzojE9H7fmOqQetOhVkPdTDbNUQu",
          //   zoom2: "https://zoom.us/meeting/register/tJ0lceqsrzkoH9aPVFCX9sbDZeWdN3B3Ee0f",
          //   color: "event4"
          // },
          // {
          //   image: "img-misty-lynne-cauthen",
          //   name: "Misty Lynne Cauthen",
          //   description: "Owner of Dragonfly Pilates<br>Trained Dancer<br>Pittsburgh, Pennsylvania",
          //   ig: "dragonflypilates",
          //   fb: "DragonflyPilates",
          //   url: "https://dragonflypilates.com/",
          //   courses: "Pilates, Barre, Bodhi Suspension System",
          //   modal: "modal2",
          //   title: "Fitting In, Within",
          //   date1: "Thursday, Sep 2, 2021",
          //   date2: "Thursday, Sep 16, 2021",
          //   banner: "Sep 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJ0qcuiqrT8tH9XZxt-zXznaTC02AhO3TB_b",
          //   zoom2: "https://zoom.us/meeting/register/tJ0sf-iorzIvG9YkEa0fP8OeudErr3aj-CTs",
          //   color: "event5"
          // },
          // {
          //   image: "img-tonya-amos",
          //   name: "Tonya Amos",
          //   description: "Owner of Aspire Pilates<br>Dancer &amp; Activist<br>Concord, California",
          //   ig: "GrownWomenDanceCollective",
          //   fb: "GrownWomenDanceCollective",
          //   url: "https://grownwomendance.org/",
          //   courses: "Pilates, AI3D",
          //   modal: "modal5",
          //   title: "Working with Black Bodies",
          //   date1: "Thursday, Oct 7, 2021",
          //   date2: "Thursday, Oct 21, 2021",
          //   banner: "Oct 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJIpcO6uqTMuGNNwMJog36HaywOO3hjArSby",
          //   zoom2: "https://zoom.us/meeting/register/tJcocuqtpzMjGdQf6prdWC0O2iUN25tVYU8z",
          //   color: "event6"
          // },
          // {
          //   image: "img-sylvia-byrd-leitner",
          //   name: "Sylvia Byrd-Leitner, MFA",
          //   description: "Certified Pilates Instructor<br>Registered Yoga Teacher<br>Cherry Hill, New Jersey",
          //   ig: "pilatescorecenter",
          //   fb: "PilatesCoreCenter",
          //   url: "http://pilatescorecenter.com",
          //   courses: "Pilates, CoreAlign, MOTR, Barre, AI3D",
          //   modal: "modal4",
          //   title: "Teaching from the Heart",
          //   date1: "Thursday, Nov 4, 2021",
          //   date2: "Thursday, Nov 18, 2021",
          //   banner: "Nov 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJAtfuCoqDMoG9byecYG4tnx69jTfK36ANYl ",
          //   zoom2: "https://zoom.us/meeting/register/tJArdu-urTkuE9GSvG6LV2XWVIxEkKwalISD",
          //   color: "event1"
          // },
          // {
          //   image: "img-fabrice-lamego",
          //   name: "Fabrice Lamego",
          //   description: "Dancer &amp; Choreographer<br>Owner: Pilates Pour Tous Studio<br>Lyon, France</p>",
          //   ig: "pilates_pour_tous_studio",
          //   fb: "Pilates-Pour-Tous-Studio-128236503940565",
          //   url: "https://pilatespourtousstudio.com",
          //   courses: "Pilates, MOTR, AI3D",
          //   modal: "modal3",
          //   title: "Building Client Skills",
          //   date1: "Thursday, Dec 2, 2021",
          //   date2: "Thursday, Dec 16, 2021",
          //   banner: "Dec 2021",
          //   zoom1: "https://zoom.us/meeting/register/tJEqdO6oqT0tHtzB3e0-D2sTNLA0QZSOSfpW",
          //   zoom2: "https://zoom.us/meeting/register/tJwqdO6urTsjHdBT-58J6RXD3HVxat0DzRRM",
          //   color: "event2"
          // },
        ]
      }
    }
  }
</script>

<style scoped>
.narrow {
  display: block;
}

.wide {
  display: none;
}

.intro {
  margin-bottom: 50px;
}

.banner {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.intro-content {
  padding: 35px 34px 35px 32px;
}

hr {
  border: none;
  border-top: 4px dotted #9EB675;
  margin: 40px 0 20px;
  width: 158px;
}

.primary {
  padding: 65px 32px;
  position: relative;
  background-color: var(--warm3);
}

.primary hr {
  border: none;
  border-top: 4px dotted #ecf2c6;
  margin: 30px 0 50px;
  width: 85px;
}

.primary .h3,
.primary .h5 {
  color: var(--green2);
}

.primary .p3 {
  color: var(--white);
  margin: 15px 0 30px;
}

.primary .p4 {
  color: var(--white);
  margin-top: 15px;
}

.primary img {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 50px;
}

.primary-inner {
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: max(268px, 35vw) 0 0 max(324px, 45vw);
  border-color: transparent transparent transparent var(--warm4);
  position: absolute;
  bottom: 0;
  left: 0;
}

.cards {
  background-color: var(--warm2);
  padding: 55px 0 0;
  position: relative;
  overflow: hidden;
}

.cards-inner {
  position: relative;
  z-index: 10;
  max-width: 1400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
}

.cards-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 max(324px, 70vw) max(268px, 60vw) 0;
  border-color: transparent var(--warm1) transparent transparent ;
  position: absolute;
  top: 0;
  right: 0;
}

.quote {
  background-color: var(--warm2);
  padding: 65px 32px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.quote-inner {
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.quote .flex {
  max-width: 700px;
}

.quote img {
  margin: 6px 10px 0 0;
}

.quote hr {
  border: none;
  border-top: 4px dotted #9eb675;
  margin: 25px 0 16px;
  width: 70px;
}

.quote .h5 {
  color: #919191;
}

@media only screen and (min-width: 768px) {
  .narrow {
    display: none;
  }

  .wide {
    display: block;
  }

  .intro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1052px;
    margin: 0 auto 50px;
    padding: 0 min(calc(1084px - 100vw), 32px);
  }

  hr {
    margin: 50px 0 20px;
  }

  .intro-content {
    order: 1;
    width: 41%;
    padding: 0;
  }

  .banner {
    order: 2;
    width: 57%;
    max-width: 607px;
    height: 510px;
  }

  .primary {
    overflow: hidden;
    padding: 96px min(calc(1084px - 100vw), 32px);
  }

  .primary-inner img {
    max-width: 277px;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .primary .p3 {
    margin-bottom: 0;
  }

  .wide-flex {
    display: flex;
    flex-flow: row nowrap;
  }

  .cards {
    padding: 96px 0 0;
  }
}
</style>
