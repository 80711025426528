<template>
  <section class="card">
    <header :class="bio.color">
      <div class="banner">{{ bio.banner }}</div>
      <h2>{{ bio.title }}</h2>
      <img
        :src="`https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg`"
        :srcset="`
          https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@1x.jpg 1x,
          https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 2x,
          https://s3.amazonaws.com/s3.pilates.com/scholarship/${bio.image}@2x.jpg 3x
        `"
        :alt="`${bio.name} headshot`"
      />
    </header>
    <div class="content">
      <h5 class="h5">FEATURING</h5>
      <p class="name">{{ bio.name }}</p>
      <p class="description" v-html="bio.description" />
      <div class="flex-links">
        <a :href="`https://www.instagram.com/${bio.ig}/`" class="ig" target="_blank"><span class="screenreader">Visit Instagram</span></a>
        <a :href="`https://www.facebook.com/${bio.fb}/`" class="fb" target="_blank"><span class="screenreader">Visit Facebook</span></a>
        <a :href="`${bio.url}`" class="web" target="_blank"><span class="screenreader">Visit Website</span></a>
      </div>
      <p class="description" v-html="bio.date" />
      <footer>
        <p class="description" v-html="bio.date1" />
        <a :href="bio.zoom1" class="h5" v-if="bio.zoom1">Register on Zoom <span>→</span></a>
        <hr>
        <p class="description" v-html="bio.date2" />
        <a :href="bio.zoom2" class="h5" v-if="bio.zoom2">Register on Zoom <span>→</span></a>
        <p v-if="bio.nolink" class="h5">Coming soon</p>
      </footer>
    </div>
  </section>
</template>

<script>
  export default {
    name: "InfoCard",

    props: {
      bio: Object
    }
  }
</script>

<style scoped>
.card {
  width: 100%;
  max-width: 310px;
  height: 490px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.2);
  margin: 10px 8px 20px;
  background-color: var(--white);
}

header {
  height: 165px;
  background-image: url(https://s3.amazonaws.com/s3.pilates.com/scholarship/pattern-triangles-wht.svg);
  background-repeat: no-repeat;
  background-position: left bottom;
  position: relative;
}

header img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50px;
  border: 2px solid #fff;
  position: absolute;
  bottom: -26px;
  right: 26px;
}

.banner {
  font-style: normal;
  font-weight: 700;
  font-size: 20rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  color: #555658;
  background-color: #fff;
  width: 165px;
  padding: 5px;
  transform: rotate(34.37deg);
  position: absolute;
  top: 20px;
  right: -30px;
  padding-left: 10px;
}

.event1 {
  background-color: var(--event1);
}

.event2 {
  background-color: var(--event2);
}

.event3 {
  background-color: var(--event3);
}

.event4 {
  background-color: var(--event4);
}

.event5 {
  background-color: var(--event5);
}

.event6 {
  background-color: var(--event6);
}

header {
  display: flex;
  align-items: center;
}

header h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20rem;
  line-height: 1.15;
  color: var(--white);
  width: 169px;
  margin: 0 32px;
}

.content {
  padding: 0 32px;
  margin-top: 20px;
}

.content .h5 {
  color: #919191;
  margin-bottom: 5px;
}

.content .name {
  font-style: normal;
  font-weight: 500;
  font-size: 15rem;
  line-height: 1.4;
  color: #3e3c53;
  margin-bottom: 10px;
}

.content .description {
  font-style: normal;
  font-weight: 400;
  font-size: 14rem;
  line-height: 1.43;
  color: #555658;
}

.flex-links {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
}

.flex-links a {
  width: 35px;
}

.flex-links a:first-of-type {
  margin-left: -5px;
}

.ig,
.fb,
.web {
  background-color: #fff;
  height: 46px;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s;
}

.ig {
  background-image: url('~@/assets/images/ig.svg');
}

.fb {
  background-image: url('~@/assets/images/fb.svg');
}

.web {
  background-image: url('~@/assets/images/web.svg');
}

a:hover.ig {
  background-image: url('~@/assets/images/ig-clr.svg');
}

a:hover.fb {
  background-image: url('~@/assets/images/fb-clr.svg');
}

a:hover.web {
  background-image: url('~@/assets/images/web-clr.svg');
}

.screenreader {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

footer {
  margin-top: 0;
  padding: 12px 0 0;
}

hr {
  border: none;
  border-top: 1px solid black;
  margin: 15px 0 10px;
  width: 100%;
}

.content footer .h5 {
  color: #242424;
  text-decoration: none;
}

.content footer .h5 span {
  vertical-align: 12%;
}

.content footer .h5:hover {
  color: var(--link);
}

@media only screen and (min-width: 768px) {
  .card {
    max-width: 300px;
  }
}
</style>
